h1 {
    @media (max-width: 500px) {
        font-size: 2rem;
    }
    @media (max-width: 300px) {
        font-size: 1.5rem;
    }
}

.img-size {
    height: 100px;
}
